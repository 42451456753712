import { Box } from '@lib/UIComponents'
import { UIStyles } from '@lib/UIComponents/types'

type BadgeProps = {
  sx?: UIStyles
  children: React.ReactNode
  variant?: 'primary' | 'gray' | 'green'
  rounded?: boolean
}

const Badge = ({ sx, variant, rounded = true, children }: BadgeProps) => {
  const variants = {
    primary: {
      bg: 'primary',
      color: 'white'
    },
    gray: {
      bg: 'grays.100',
      color: 'grays.600'
    },
    green: {
      bg: 'greens.0',
      color: 'greens.600'
    }
  }

  const shapes = {
    rounded: { borderRadius: 'circle' }
  }

  return (
    <Box
      as="span"
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        px: '10px',
        py: 1,
        bg: 'grays.50',
        color: 'grays.650',
        borderRadius: 'default',
        fontSize: ['xs', 'xs', 'md'],
        lineHeight: 1.5,
        ...(variant && variants[variant]),
        ...(rounded && shapes.rounded),
        ...sx
      }}
    >
      {children}
    </Box>
  )
}

export default Badge
