import { isNil } from 'ramda'
import { MouseEventHandler } from 'react'

import { pluralize } from '@utils/strings'
import { Box, Text } from '@lib/UIComponents'
import { UIStyles } from '@lib/UIComponents/types'
import RatingStars from './RatingStars'

type RatingsProps = {
  average: number
  total?: number
  onClick?: Function
  sx?: UIStyles
}

const styles = {
  wrapper: (clickable: boolean) => ({
    display: 'inline-flex',
    cursor: clickable ? 'pointer' : 'default',
    zIndex: clickable ? 'auto' : 1
  })
}

const Ratings = ({ average, total, onClick, sx = {} }: RatingsProps) => {
  if (!total && !average) return null

  return (
    <Box
      sx={{
        ...styles.wrapper(Boolean(onClick)),
        ...sx
      }}
      onClick={onClick as MouseEventHandler}
    >
      <RatingStars
        starNumber={average}
        sx={{ cursor: Boolean(onClick) && 'pointer' }}
        starsSx={{ fontSize: 2 }}
      />

      {!isNil(total) && (
        <Text
          as="span"
          sx={{
            fontSize: 'sm',
            ml: 2,
            color: 'primary'
          }}
        >
          {total} {pluralize(total, 'Review', 'Reviews')}
        </Text>
      )}
    </Box>
  )
}

export default Ratings
