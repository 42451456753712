import Currency from '@atoms/UICurrency/Currency'
import { Text } from '@lib/UIComponents'
import { UIStyles } from '@lib/UIComponents/types'

type CurrencyFreeProps = {
  value: number
  text?: string
  freeColor?: string
  sx?: UIStyles
}

const CurrencyFree = ({
  value,
  sx = {},
  freeColor = '',
  text = 'Free'
}: CurrencyFreeProps) => {
  return value > 0 ? (
    <Currency sx={sx}>{value}</Currency>
  ) : (
    <Text sx={{ ...sx, color: freeColor }}>{text}</Text>
  )
}

export default CurrencyFree
