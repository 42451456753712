import analytics from '@lib/gtm/analytics'
import { Sale, Maybe } from 'src/generated/graphql'

type EventAttrs = {
  eventLabel?: string
  eventValue?: string | number
}

export type AnalyticsSale = {
  databaseId?: Maybe<number>
  name?: string
}

type IntelligentModulesAnalytics = {
  trackSale: (label: string) => void
  trackProductClick: (
    pageSection: string,
    pageSectionPosition: number,
    item: Sale
  ) => void
}

const DEFAULT_CATEGORY = 'Intelligent Module'

const parseItem = (item: Sale): AnalyticsSale => ({
  databaseId: item.databaseId,
  name: item.name
})

const track = (action: string, attrs: EventAttrs = {}): void => {
  analytics.track(DEFAULT_CATEGORY, action, attrs)
}

const intelligentModuleAnalytics: IntelligentModulesAnalytics = {
  trackSale: (label) => {
    track('Clicked Sale', {
      eventLabel: label
    })
  },

  trackProductClick: (pageSection, pageSectionPosition, item) => {
    analytics.trackSCDataLayer('Carousel Click', {
      pageSection,
      pageSectionPosition,
      item: parseItem(item)
    })
  }
}

export default intelligentModuleAnalytics
