const SUBDOMAINS_NOT_TO_INDEX = [
  'api.stacksocial.com',
  'exclusives.stacksocial.com',
  'partners.stacksocial.com',
  'partners2.stacksocial.com',
  'partners3.stacksocial.com',
  'partners5.stacksocial.com',
  'shops1.stacksocial.com',
  'shops2.stacksocial.com'
]

type MetaRobotsProps = {
  customMetaRobots?: string
  noIndex: boolean
  hostname: string
}
type MetaRobots = { metaRobots: string }

const useMetaRobots = ({
  customMetaRobots,
  noIndex,
  hostname
}: MetaRobotsProps): MetaRobots => {
  const publisherMetaRobots =
    (noIndex || SUBDOMAINS_NOT_TO_INDEX.includes(hostname)) &&
    'noindex,nofollow'

  return {
    metaRobots: publisherMetaRobots || customMetaRobots || ''
  }
}

export default useMetaRobots
