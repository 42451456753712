import gql from 'graphql-tag'
import { pathOr } from 'ramda'
import { WishlistOperation, SetWishlistPayload } from 'src/generated/graphql'
import { TApolloClient } from 'src/types/apollo'

export const SET_WISHLIST_MUTATION = gql`
  mutation SetWishlist($input: SetWishlistInput!) {
    setWishlist(input: $input) {
      success
      favorite {
        databaseId
        sale {
          databaseId
          name
          priceInCents
          slug
          saleGroupOption {
            databaseId
            name
            saleGroupId
          }
          category {
            name
          }
        }
      }
      errors {
        message
        path
      }
    }
  }
`

const SetWishlist = (
  operation: WishlistOperation,
  saleId: number,
  apolloClient: TApolloClient
): Promise<SetWishlistPayload> =>
  apolloClient
    .mutate({
      mutation: SET_WISHLIST_MUTATION,
      variables: {
        input: { operation, saleId }
      }
    })
    .then(pathOr({} as SetWishlistPayload, ['data', 'setWishlist']))

export default SetWishlist
