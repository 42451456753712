export type SaleVariants = 'default' | 'small' | 'collection' | 'giveaway'

type CSSVariant = {
  card?: object
  image: {
    img: object
    position?: string
    placeholder: object
    paddingTop?: string
    width?: string
  }
  defaultInfo?: object
  prices?: object
  categoryLink?: object
  priceInCents?: object
  title?: object
  texts?: object
  name?: object
}

export const variants: Record<SaleVariants, CSSVariant> = {
  small: {
    card: {
      width: ['225px', '225px', '210px'],
      minHeight: ['340px', '340px', '320px'],
      a: { minHeight: ['340px', '340px', '320px'] }
    },
    image: {
      img: {
        width: ['223px', '223px', '208px'],
        minHeight: ['167px', '167px', '156px']
      },
      placeholder: {
        width: ['167px', '167px', '156px']
      }
    },
    defaultInfo: {
      height: ['180px', '180px', '140px'],
      paddingTop: '8px'
    },
    prices: {
      mt: ['10px', '25px', 0],
      flexDirection: 'row'
    },
    priceInCents: {
      ml: 2,
      fontSize: [1, 2]
    }
  },
  collection: {
    card: {
      pb: 2
    },
    defaultInfo: {
      pt: '24px'
    },
    categoryLink: {
      marginTop: '75%',
      top: '8px'
    },
    image: {
      position: 'relative',
      paddingTop: '75%',
      width: '100%',
      img: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        minWidth: '100%',
        minHeight: '100%',
        inset: 0
      },
      placeholder: {
        paddingTop: '75%'
      }
    },
    name: {
      lineHeight: '1.125',
      minHeight: '1.20em',
      WebkitLineClamp: '2',
      pb: [0, '3px']
    },
    title: {
      lineHeight: '1.125', // Default is 1.2, but varies by browser
      minHeight: '2.25em', // 2 * 1em * 1.125 // (number of lines) * (font-size) * (line-height multiplier),
      display: '-webkit-box',
      pb: [0, '2px']
    },
    texts: {
      minHeight: 'auto'
    },
    prices: {
      mt: 3
    },
    priceInCents: {
      ml: 2
    }
  },
  default: {
    card: {
      width: ['235px', '235px', '264px'],
      minHeight: ['325px', '325px', '392px'],
      a: { minHeight: ['325px', '325px', '392px'] }
    },
    image: {
      img: {
        width: ['233px', '233px', '262px'],
        minHeight: ['174px', '174px', '196px']
      },
      placeholder: {
        width: ['233px', '233px', '262px'],
        minHeight: ['174px', '174px', '196px']
      }
    },
    defaultInfo: {
      height: ['186px', '186px', '205px'],
      paddingTop: '24px'
    },
    prices: {
      mt: ['10px', '25px', 0],
      flexDirection: 'row'
    },
    priceInCents: {
      ml: 2,
      fontSize: [1, 2]
    }
  },
  giveaway: {
    card: {
      width: ['235px', '235px', '264px'],
      minHeight: ['325px', '325px', '392px'],
      a: { minHeight: ['325px', '325px', '392px'] }
    },
    image: {
      img: {
        width: ['233px', '233px', '262px'],
        minHeight: ['174px', '174px', '196px']
      },
      placeholder: {
        width: ['233px', '233px', '262px'],
        minHeight: ['174px', '174px', '196px']
      }
    },
    defaultInfo: {
      height: ['186px', '186px', '205px'],
      paddingTop: '24px'
    },
    prices: {
      marginTop: 4,
      flexDirection: 'row'
    },
    priceInCents: {
      ml: 2,
      fontSize: [1, 2]
    }
  }
}
