import {
  Publisher,
  Favorite,
  WishlistOperation,
  SetWishlistPayload
} from 'src/generated/graphql'
import { TApolloClient } from 'src/types/apollo'
import { captureMessage, captureException } from '@sentry/react'

import SetWishlistRepository from '@concepts/Wishlist/repository/SetWishlist'
import SaleDTO from '@concepts/Cart/dto/SaleDTO'

import segmentAnalytics from '@lib/segment/analytics'
import analytics from '@lib/gtm/analytics'

import { setCookie } from '@utils/cookies'
import { FAVORITE_SALE_COOKIE_NAME } from '@config/env'
import * as auth from '@concepts/Auth/utils/redirect'

export type WishlistSource = 'Intelligent Module' | 'Sale' | 'User Page'

export type WishlistParams = {
  saleId: number
  operation: WishlistOperation
  publisher: Partial<Publisher>
  source: WishlistSource
  isSignedIn: boolean
}

type WishlistAnalytics = {
  favorite: Favorite
  source: WishlistSource
  publisher: Partial<Publisher>
}

const redirectToSignUpPage = (saleId: number) => {
  setCookie(FAVORITE_SALE_COOKIE_NAME, String(saleId))
  auth.redirectToSignUpPage()
}

const analyticsEvents = (
  operation: WishlistOperation,
  data: WishlistAnalytics
) =>
  ({
    ADD: addedToWishlistAnalytics,
    REMOVE: removedFromWishlistAnalytics
  })[operation](data)

const addedToWishlistAnalytics = ({
  favorite,
  publisher,
  source
}: WishlistAnalytics) => {
  const sale = SaleDTO.parse(favorite.sale)

  segmentAnalytics.productAddedToWishlist({
    favorite,
    sale,
    publisher
  })

  analytics.track(source, 'Add item to Wishlist', { saleId: sale.id })
  analytics.trackSCDataLayer('ADD_TO_WISHLIST', {
    productId: sale.id,
    productName: sale.name
  })
}

const removedFromWishlistAnalytics = ({
  favorite,
  source
}: WishlistAnalytics) => {
  const sale = SaleDTO.parse(favorite.sale)

  analytics.track(source, 'Remove Wishlist Item', { saleId: sale.id })
  analytics.trackSCDataLayer('REMOVE_FROM_WISHLIST', {
    productId: sale.id,
    productName: sale.name
  })
}

const SetWishlist = async (
  { saleId, operation, publisher, source, isSignedIn }: WishlistParams,
  apolloClient: TApolloClient
): Promise<SetWishlistPayload> => {
  if (!isSignedIn) {
    redirectToSignUpPage(saleId)
    return Promise.resolve({ success: true, favorite: null, errors: [] })
  }

  return SetWishlistRepository(operation, saleId, apolloClient)
    .then((response) => {
      const { success, errors, favorite } = response

      if (!success) {
        captureMessage(`Error on Sale Wishlist: ${errors}`)
        return Promise.reject(new Error('Wishlist Error'))
      }

      /* istanbul ignore else */
      if (favorite) {
        analyticsEvents(operation, {
          favorite,
          publisher,
          source
        })
      }

      return response
    })
    .catch((err) => {
      captureException(err)
      return Promise.reject(err)
    })
}

export default SetWishlist
