import ecommerceAnalytics from '@lib/gtm/events/ecommerce'
import trackIntelligentModule from '@lib/gtm/events/intelligentModules'
import { Sale } from 'src/generated/graphql'

export type AnalyticsProps = {
  position: number
  module: string
  page?: string
  pageSectionPosition?: number
  pageSectionTitle?: string
}

const shouldTrackSale = (analytics: AnalyticsProps) => analytics.page
const shouldTrackProductClick = (analytics: AnalyticsProps) =>
  analytics.pageSectionTitle && analytics.pageSectionPosition

export const trackAnalytics = (analytics: AnalyticsProps, item: Sale): void => {
  ecommerceAnalytics.trackProductClick(
    item,
    analytics.module,
    analytics.position
  )

  if (shouldTrackSale(analytics)) {
    trackIntelligentModule.trackSale(`${analytics.module}-${analytics.page}`)
  }

  if (shouldTrackProductClick(analytics)) {
    trackIntelligentModule.trackProductClick(
      analytics.pageSectionTitle as string,
      analytics.pageSectionPosition as number,
      item
    )
  }
}
