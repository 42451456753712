import { useCallback, useEffect, useState } from 'react'
import { WishlistOperation } from 'src/generated/graphql'
import { SxStyleProp } from 'rebass'

import { usePublisherContext } from '@concepts/Publisher/store/context'
import { useAuthContext } from '@concepts/Auth/store/context'
import SetWishlist, { WishlistSource } from '@concepts/Wishlist/app/SetWishlist'

import { useAuthApollo } from '@lib/apollo/index'
import { isPresent } from '@utils/logic'

import Button, { Props as ButtonProps } from '@atoms/Button/Button'
import { WishlistIcon } from '@atoms/Icon/Icon'

const styles = (favorited: boolean): SxStyleProp => ({
  position: 'absolute',
  top: 4,
  right: 4,
  border: 0,
  padding: 1,
  background: 'white',
  transition: 'color 200ms',
  color: favorited ? 'primary' : 'black',
  svg: {
    fill: favorited ? 'primary' : 'none',
    stroke: favorited ? 'primary' : 'black'
  }
})

const buttonAction = (isFavorited: boolean) =>
  isFavorited
    ? {
        operation: WishlistOperation.Remove,
        label: 'Remove from wishlist'
      }
    : {
        operation: WishlistOperation.Add,
        label: 'Add to wishlist'
      }

type Props = {
  saleId: number
  source: WishlistSource
} & ButtonProps

const WishlistButton: React.FC<React.PropsWithChildren<Props>> = ({
  saleId,
  source,
  sx,
  ...props
}) => {
  const apolloClient = useAuthApollo()
  const publisher = usePublisherContext()
  const [{ isSignedIn, user }] = useAuthContext()

  const isOnUserWishlist = useCallback(
    (list?: Array<number>) => (list || []).includes(saleId),
    [saleId]
  )

  const [isFavorited, setFavorited] = useState(
    isOnUserWishlist(user?.favoriteSaleIds)
  )

  const { label, operation } = buttonAction(isFavorited)

  useEffect(() => {
    setFavorited(isOnUserWishlist(user?.favoriteSaleIds))
  }, [saleId, user?.favoriteSaleIds, isOnUserWishlist])

  const onClick = useCallback(() => {
    setFavorited((prevState) => !prevState)

    SetWishlist(
      {
        saleId,
        operation,
        publisher,
        source,
        isSignedIn: isSignedIn && isPresent(user)
      },
      apolloClient
    ).catch(() => setFavorited((prevState) => !prevState))
  }, [saleId, operation, publisher, source, isSignedIn, user, apolloClient])

  return (
    <Button
      variant="icon"
      sx={{ ...styles(isFavorited), ...sx }}
      aria-label={label}
      title={label}
      onClick={onClick}
      {...props}
    >
      <WishlistIcon />
    </Button>
  )
}

export default WishlistButton
