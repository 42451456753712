import Head from 'next/head'
import { useRouter } from 'next/router'
import { Maybe } from 'src/types/maybe'
import useMetaRobots from './hooks/useMetaRobots'
import useSeo from './hooks/useSeo'
import { BASE_URL } from '@config/env'

export type OgMetaDataType = {
  content: string
  description: string
  title: string
  image?: string
}

export type TwitterMetaDataType = {
  card: string
  title: string
  description: string
  image?: string
  dataOne: string
  labelOne: string
  dataTwo: string
  labelTwo: string
}

type SeoType = {
  page?: string
  customTitle?: string
  customMetaRobots?: string
  customMetaDescription?: Maybe<string>
  customCanonicalUrl?: string
  ogMeta?: OgMetaDataType
  twitterMeta?: TwitterMetaDataType
}

const Seo: React.FC<React.PropsWithChildren<SeoType>> = ({
  page = '',
  customTitle,
  customMetaRobots,
  customMetaDescription,
  customCanonicalUrl = '',
  ogMeta,
  twitterMeta
}) => {
  const {
    publisherTitle,
    description,
    favicon,
    isStackSocial,
    hostname,
    noIndex,
    fbId
  } = useSeo()
  const { metaRobots } = useMetaRobots({
    customMetaRobots,
    hostname,
    noIndex
  })
  const trimmedCustomTitle = customTitle && customTitle.trim()

  const pagePipe = page && publisherTitle ? ' | ' : ''
  const hasMetaDescription =
    customMetaDescription || `${page || ''} ${description}`.trim()
  const title = trimmedCustomTitle || `${page}${pagePipe}${publisherTitle}`

  const { asPath } = useRouter()

  const url = hostname + asPath

  const canonicalUrl = customCanonicalUrl || `${BASE_URL}${asPath}`

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={hasMetaDescription} />

      {twitterMeta && (
        <>
          <meta name="twitter:card" content={twitterMeta.card} />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={hasMetaDescription} />
          <meta name="twitter:image:src" content={twitterMeta.image} />
          <meta name="twitter:data1" content={twitterMeta.dataOne} />
          <meta name="twitter:label1" content={twitterMeta.labelOne} />
          <meta name="twitter:data2" content={twitterMeta.dataTwo} />
          <meta name="twitter:label2" content={twitterMeta.labelTwo} />
          <meta name="twitter:domain" content={hostname} />
          <meta name="twitter:site" content={`@${publisherTitle}`} />
          <meta name="twitter:creator" content={`@${publisherTitle}`} />
        </>
      )}

      {fbId && <meta property="fb:app_id" content={fbId} />}

      {ogMeta && (
        <>
          <meta property="og:type" content={ogMeta.content} />
          <meta property="og:description" content={hasMetaDescription} />
          <meta property="og:title" content={title} />
          <meta property="og:image" content={ogMeta.image} />
          <meta property="og:site_name" content={publisherTitle} />
          <meta property="og:url" content={url} />
        </>
      )}

      <link rel="shortcut icon" href={favicon} key="favicon" />
      <link rel="apple-touch-icon" href={favicon} key="apple-touch" />

      <link rel="canonical" href={canonicalUrl} />

      {metaRobots && <meta name="robots" content={metaRobots} />}

      {isStackSocial && (
        <>
          <link
            rel="apple-touch-icon-precomposed"
            sizes="152x152"
            href="/images/icons/apple-touch-icon-152x152-precomposed.png"
            key="apple-touch-precomposed-152x152"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="120x120"
            href="/images/icons/apple-touch-icon-120x120-precomposed.png"
            key="apple-touch-precomposed-120x120"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="76x76"
            href="/images/icons/apple-touch-icon-76x76-precomposed.png"
            key="apple-touch-precomposed-76x76"
          />
        </>
      )}
    </Head>
  )
}

export default Seo
