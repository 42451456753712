import { Sale } from 'src/generated/graphql'

import Badge from '@atoms/Badge/Badge'

type ProductCardFlagsProps = {
  sale: Sale
}

type Flag = {
  type: 'priceDrop' | 'freeShipping' | 'newDeal'
  label: string
}

const FLAGS: Flag[] = [
  {
    type: 'priceDrop',
    label: 'Price Drop'
  },
  {
    type: 'freeShipping',
    label: 'Free Shipping'
  },
  {
    type: 'newDeal',
    label: 'New Deal'
  }
]

const ProductCardFlags = ({ sale }: ProductCardFlagsProps) => {
  const flag = FLAGS.find((flag) => sale[flag.type])

  return (
    <>
      {flag && (
        <Badge
          sx={{
            fontSize: 'xs',
            fontWeight: 'bold',
            position: 'absolute',
            top: 4,
            left: 4,
            zIndex: 1,
            color: `${flag.type}TextColor`,
            backgroundColor: `${flag.type}BackgroundColor`
          }}
        >
          {flag.label}
        </Badge>
      )}
    </>
  )
}

export default ProductCardFlags
