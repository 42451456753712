import { pathOr, mergeDeepRight } from 'ramda'

import Card from '@atoms/UICard/Card'
import Currency from '@atoms/UICurrency/Currency'
import Picture from '@atoms/UIPicture/PictureAttributes'
import UseLink from '@atoms/UILink/UseLink'

import { SaleResponsiveImageKey } from 'src/types/domain'
import { Sale } from 'src/generated/graphql'

import { SaleVariants, variants } from './variants'
import CurrencyFree from '@atoms/UICurrency/CurrencyFree'

import WishlistButton from '@concepts/Wishlist/views/WishlistButton'
import Ratings from '@molecules/Ratings/Ratings'
import ProductCardFlags from '@molecules/ProductCardFlags/ProductCardFlags'

import { trackAnalytics, AnalyticsProps } from './utils/analytics'
import { Box, Flex, Text } from '@lib/UIComponents'
import { ResponsiveImage } from '@atoms/UIPicture/types/picture'

export enum ShowcaseType {
  GIVEAWAYS = 'giveaways',
  SALES = 'sales'
}

type Props = {
  item: Sale
  imageId: SaleResponsiveImageKey
  onClick?: Function
  useLink?: boolean
  analytics?: AnalyticsProps
  onClickItem?: () => void
  openModal?: Function
  variant?: SaleVariants
  badgeText?: string
  type?: ShowcaseType
  showWishlistButton?: boolean
}

const styles = {
  card: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    flexShrink: 0,
    p: 0,
    textAlign: 'left',
    fontFamily: 'body',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
    borderColor: 'grays.100'
  },
  image: {
    img: {
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4
    },
    display: 'block'
  },
  defaultInfo: {
    px: [4, 5],
    pt: '1px',
    fontSize: ['0.75rem', '0.75rem', 1],
    flexDirection: 'column',
    borderTop: '1px solid',
    borderColor: 'grays.100'
  },
  categoryLink: {
    position: 'absolute',
    top: ['185px', '185px', '210px'],
    left: [4, '18px']
  },
  category: {
    fontSize: ['0.75rem', '0.75rem', 1],
    marginBottom: 3
  },
  texts: {
    flexDirection: 'column',
    height: [null, '96px', '96px']
  },
  name: {
    fontSize: [1, 1, 2],
    minHeight: 90,
    display: '-webkit-box',
    WebkitLineClamp: '4',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'bold'
  },
  reviews: {
    marginTop: 1
  },
  prices: {
    marginTop: '0.5rem'
  },
  priceInCents: {
    textDecoration: 'line-through',
    color: 'grays.600'
  }
}

const callTrackAnalytics = (
  analytics: AnalyticsProps | undefined,
  item: Sale
): void => {
  if (!analytics) return

  trackAnalytics(analytics, item)
}

const ShowcaseItem = ({
  item,
  imageId,
  useLink = true,
  analytics,
  onClickItem,
  openModal,
  variant = 'default',
  type = ShowcaseType.SALES,
  showWishlistButton = true
}: Props) => {
  const reviews = {
    total: pathOr(0, ['ratings', 'totalCount'], item),
    average: pathOr(0, ['ratings', 'averageScore'], item)
  }

  const showPriceInCents = item.priceInCents > item.priceWithDiscountInCents

  const isGiveaways = type === ShowcaseType.GIVEAWAYS
  const to = isGiveaways ? '/giveaways' : '/sales'
  const saleLinkProps = { to: `${to}/${item.slug}`, external: true }

  const variantStyles = mergeDeepRight(styles, variants[variant])
  const priceWithDiscount =
    item.priceDiscountPercentage > 0 ? `${item.priceDiscountPercentage}%` : null

  return (
    <Card as="article" data-testid="showcase-item" sx={variantStyles.card}>
      <Box
        onClick={() => {
          if (!useLink && openModal) openModal(item)
        }}
      >
        <ProductCardFlags sale={item} />

        <UseLink
          onClick={() => {
            callTrackAnalytics(analytics, item)
            if (onClickItem) onClickItem()
          }}
          useLink={useLink}
          title={item.name}
          data-testid="showcase-item-link"
          {...saleLinkProps}
          sx={{ '&:hover': { textDecoration: 'none' } }}
        >
          <Box sx={{ position: 'relative' }}>
            <Picture
              responsiveImage={
                item.imageCollection?.[imageId] as ResponsiveImage
              }
              alt={item.name}
              sx={variantStyles.image}
              imagePlaceholder={
                <Box sx={variants[variant].image.placeholder} />
              }
            />
          </Box>
          <Flex sx={variantStyles.defaultInfo}>
            <Flex sx={variantStyles.texts}>
              <Text as="strong" sx={variantStyles.name}>
                {item.name}
              </Text>
            </Flex>
            <Flex
              sx={{
                flexFlow: 'column',
                height: '53px',
                justifyContent: 'end',
                fontSize: ['sm', 'sm', 'md']
              }}
            >
              <Ratings {...reviews} sx={variantStyles.reviews} />

              <Flex
                sx={variantStyles.prices}
                data-attribute={priceWithDiscount}
              >
                <CurrencyFree
                  freeColor="primary"
                  sx={{
                    ...variants[variant].priceInCents,
                    textTransform: 'uppercase',
                    m: 0
                  }}
                  value={item.priceWithDiscountInCents}
                />
                {showPriceInCents && (
                  <Currency sx={variantStyles.priceInCents}>
                    {item.priceInCents}
                  </Currency>
                )}
              </Flex>
            </Flex>
          </Flex>
        </UseLink>
      </Box>
      {showWishlistButton && (
        <WishlistButton
          saleId={item.databaseId as number}
          source="Intelligent Module"
        />
      )}
    </Card>
  )
}

export default ShowcaseItem
