import { StarIcon } from '@atoms/Icon/Icon'

import { getStarsWidth, RATING_STARS_RANGE } from './utils'
import { UIStyles } from '@lib/UIComponents/types'
import { Box, Flex } from '@lib/UIComponents'

type Props = {
  starNumber?: number
  sx?: UIStyles
  starsSx?: UIStyles
}

const RatingStars = ({ starNumber, sx = {}, starsSx = {} }: Props) => {
  if (!starNumber) return null

  return (
    <Box
      sx={{
        display: 'inline-flex',
        cursor: 'default',
        zIndex: 'auto',
        ...sx
      }}
    >
      <Flex
        sx={{
          backgroundColor: 'transparent',
          position: 'relative',
          fontSize: ['md', 'xl'],
          alignItems: 'center'
        }}
      >
        {RATING_STARS_RANGE.map((value) => {
          return (
            <Box
              key={value}
              sx={{
                m: 0,
                p: 0,
                position: 'relative',
                svg: {
                  color: 'grays.100'
                },
                ...starsSx
              }}
            >
              <StarIcon />
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: getStarsWidth(value, starNumber),
                  overflow: 'hidden',
                  svg: {
                    color: 'primary !important'
                  }
                }}
              >
                <StarIcon />
              </Box>
            </Box>
          )
        })}
      </Flex>
    </Box>
  )
}

export default RatingStars
